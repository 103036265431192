import React from "react"
import Seo from "../components/seo"
import "../styles/theme.scss"
import Layout from "../layouts/layout";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql } from "gatsby"

export default class Article extends React.Component {
    render() {
        let global = this.props.data.contentfulWebsite;
        return (
            <Layout pathname={this.props.location.pathname}>
                <Seo metaTitle={`${this.props.pageContext.title} - ${global.title}`} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
                <div className="o-wrapper o-content o-container">
                    <div className="o-content-spacer o-content-container">
                        <h1 className="u-text-center">{this.props.pageContext.title}</h1>
                        <div className="o-content-container">
                            {documentToReactComponents(JSON.parse(this.props.pageContext.content.raw))}
                        </div>
                    </div>
                </div>
            </Layout>
        );
    };
}

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "UNINTERRUPTED Canada"}) {
        author
        description
        title
        shareImage {
            file {
                url
            }
        }
    }
}
`;
